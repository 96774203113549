/* 请求封装 */
import axios from 'axios'
import qs from 'qs'
import { isAndroid, isiOS } from '@/utils'

const apiFront = process.env.VUE_APP_API_PATH

// 添加请求拦截器，提交的时候加密
axios.interceptors.request.use(function (config) {
  return config
}, function (error) {
  return Promise.reject(error)
})

// 添加返回拦截，返回的时候解密
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

const fetch = async (options) => {
  let {
    method = 'get',
    data,
    url,
    type
  } = options
  url = `${apiFront}/${url}`

  let headers = {
    'App-Header':'H5',
    // 省一下的登录注册需要这样
    device: encodeURIComponent(JSON.stringify({
      appName: 'JYD', // YSD , SYX
      appSourceId: 0,
      device: isiOS ? 'ios' : 'android',
    }))
  }

  let fetch
  switch (method.toLowerCase()) {
    case 'get':
      fetch = axios.get(url, {
        params: data,
        // withCredentials: true,
        headers: {
          ...headers
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      })
      break
    case 'post':
      fetch = axios.post(url, data, {
        headers
      }, {
        transformRequest: [function (data) {
          data = qs.stringify(data)
          return data
        }],
        // withCredentials: true
      })
      break
    default:
      fetch = axios({ ...options })
      break
  }
  return fetch
}

const request = function (option) {
  const {
    url,
    data,
    method
  } = option

  const options = {
    url,
    data,
    method
  }

  return fetch(options)
    .then((response) => {
      const { config } = response
      const { url } = config
      const data = response.data

      if (data.code === '500') {
        throw new Error(data.message)
      }

      return Promise.resolve(data)

    })
    .catch((error) => {
      const { response } = error
      let msg
      let statusCode
      if (response && response instanceof Object) {
        const {
          data,
          statusText
        } = response
        statusCode = response.status
        msg = data.message || statusText
      } else {
        statusCode = 600
        msg = error.message || '服务器开小差了...'
        if (error.message === 'Network Error') {
          msg = '服务器开小差了'
        }
      }

      /* eslint-disable */
      return Promise.reject({
        success: false,
        statusCode,
        message: msg
      })
    })
}

export default {
  // get方法封装
  get: function (url, data, type) {
    const config = {
      url,
      data: data ? data : '',
      method: 'GET'
    }
    if (type) config.type = type
    return request({
      ...config
    })
  }, // post方法封装
  post: function (url, data, type) {
    const config = {
      url,
      data: data ? data : '',
      method: 'POST'
    }
    if (type) config.type = type
    return request({
      ...config
    })
  }
}
