<template>
  <div class="page">
    <div class="content">

      <div class='form'>
        <div class='f-item f-item-verify'>
          <div class='f-input'>
            <input type='number' maxlength="11" pattern="[0-9]*" v-model='mobile' placeholder='请输入手机号' class='input'/>
          </div>
        </div>
        <div class='f-item f-item-verify'>
          <div class='f-input f-input-verify'>
            <input type='text' maxlength="6" v-model='verifyCode' placeholder='请输入验证码' class='input'/>
          </div>
          <div @click="handleVerifyCode" class='f-btn-verify'>{{ verifyTxt }}</div>
        </div>
      </div>
      <div @click="handleClick" class="btn">马上拿钱</div>
      <!--协议-->
      <div class="tips">

        <van-checkbox class="mc-radio" v-model="radio" name="checked">
          <template #icon="props">
            <img class="img-icon" alt="radio-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
          </template>
          <span>我已阅读<a target="_blank" href="https://res.nnbreath.com/contract/private.html"> 《隐私协议》</a>和<a
            target="_blank"
            href="https://res.nnbreath.com/contract/service.html">《注册协议》</a></span>
        </van-checkbox>

      </div>
    </div>
  </div>

</template>
<script>
import API from '@/server/api'
const app = createApp()
import {createApp, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import {GetUrlParams, isiOS} from '@/utils'
import {Toast, Checkbox, CheckboxGroup} from 'vant'
import 'vant/lib/index.css'

app.use(Toast)
let TimeOutCount = null

export default defineComponent({
  components: {
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  setup(props) {

    const state = reactive({
      countdown: 60,
      verifyTxt: '获取验证码',
      canSendCode: true,
      mobile: '',
      verifyCode: '',
      smsDownCacheCode: '',
      url: '',

      radio: false,
      activeIcon: 'https://res.jqtianxia.com/img/2.png',
      inactiveIcon: 'https://res.jqtianxia.com/img/1.png',
    })

    onMounted(() => {

      const ua = navigator.userAgent.toLowerCase()
      console.log(ua)

      const urlQuery = GetUrlParams()
      const {source} = urlQuery
      if (source) {
        console.log(source,'===')
        state.url = source
      }
    })

    // 判断手机号是否正确
    const validMobile = (val) => {
      return (/^1[3456789]\d{9}$/.test(val))
    }

    // 点击提交
    const handleClick = async () => {
      const ua = navigator.userAgent.toLowerCase()
      const isXin = ua.indexOf('micromessenger') !== -1

      const {
        mobile,
        verifyCode,
        url,
        smsDownCacheCode
      } = state

      if (!mobile) {
        Toast('请输入手机号码')
        return false
      }
      if (!validMobile(mobile)) {
        Toast('请输入正确的手机号码')
        return false
      }

      if (!verifyCode && verifyCode.length !== 4) {
        Toast('请输入正确的验证码')
        return false
      }


      if (!state.radio) {
        Toast('请先阅读隐私、注册协议，并勾选确认')
        return false
      }

      let data = {
        'appName': 'JYD',
        'cell': `${mobile}`,
        'code': `${verifyCode}`,
        'smsDownCacheCode': `${smsDownCacheCode}`,
        'userType': '0',
        'promoType': '1',
        'url': `${url}`
      }

      try {
        const res = await API.register(data)
        if (res.success || res.msg === '您已经是老用户了，请登录本应用的APP借款') {
          if (!isXin) {
            if (isiOS) {
              window.location.href = 'https://apps.apple.com/cn/app/%E7%99%BE%E7%81%B5%E7%BE%8E%E9%80%89/id1670257906'
            } else {
              window.location.href = 'https://jyd.bailingmx.com/android/blmx.apk'
            }
          } else {
            alert('请在手机默认浏览器当中打开')
          }
        } else {
          Toast(res.msg || res.message)
        }
      } catch (err) {

        Toast(err.msg || err.message)
      }

    }

    // 发送验证码
    const handleVerifyCode = async () => {
      if (!state.canSendCode) return
      const {mobile} = state
      if (!mobile) {
        Toast('请输入手机号码')
        return false
      }
      if (!validMobile(mobile)) {
        Toast('请输入正确的手机号码')
        return false
      }

      if (!state.radio) {
        Toast('请先阅读隐私、注册协议，并勾选确认')
        return false
      }

      let dataParams = {
        cell: mobile,
        authType: '1'
      }
      try {
        const res = await API.sendVerifyCode(dataParams)
        const data = res.result
        if (res.success) {
          Toast.success('发送成功')
          initCountDown()
        } else {
          Toast('发送失败')
        }
      } catch (e) {
        Toast('发送失败')
      }

    }

    // 倒计时
    const initCountDown = () => {
      let {countdown} = state
      if (state.countdown === 1) {
        state.verifyTxt = '获取验证码'
        state.canSendCode = true
        state.countdown = 60

        return false
      } else {
        state.verifyTxt = `${countdown}秒后`
        state.canSendCode = false
        state.countdown = --countdown
      }
      if (TimeOutCount != null) clearTimeout(TimeOutCount)
      TimeOutCount = setTimeout(() => {
        initCountDown()
      }, 1000)
    }

    return {
      ...toRefs(state),
      handleClick,
      handleVerifyCode
    }

  }
})
</script>
<style lang="less" src="./index.less"></style>
