/* 请求的接口封装 */
import axios from 'axios'
import request from '../utils/fetch'

export default {
  /* 还款试算 */
  // async initLoanTrial (data) {
  //   return request.post('loan/fee', data)
  // },
  // //  省一下 发送验证码
  // async sendVerifyCode (data) {
  //   return request.post('app/v1/sendCode', data)
  // },
  // //  省一下 登录/注册
  // async register (data) {
  //   return request.post('app/v1/verify', data)
  // },
  async sendVerifyCode (data) {
    return request.get('login/v1/send', data)
  },
  async register (data) {
    return request.post('login/v1/register', data)
  },
  // async ajax(data){
  //   return request.post('app/htmlGateway.do', data)
  // }
}
