// 格式化金额，数字
const formatMoney = function (s, n) {
  console.log('formatMoney')
}

// 格式化两位小数
const money = function (s, n) {
  if (!s) s = 0
  if (!n) n = 2
  n = n > 0 && n <= 20 ? n : 2
  const ns = (s + '').replace(/[^\d.-]/g, '')
  s = parseFloat(ns).toFixed(n) + ''
  const l = s.split('.')[0].split('').reverse(),
    r = s.split('.')[1]
  let t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
  }
  return t.split('').reverse().join('') + '.' + r
}

// 获取hash路由URL的参数
const GetUrlParams = function (href) {

  const u = href ? href : window.location.href
  const o = {}
  const startNum = u.indexOf('?')
  if (startNum !== -1) {
    const paramsStr = u.substring(startNum + 1)
    if (paramsStr.indexOf('&') !== -1) {
      let cacheArr = paramsStr.split('&')
      cacheArr.forEach((item) => {
        let arr = item.split('=')
        o[arr[0]] = arr[1]
      })
    } else {
      let paramsArr = paramsStr.split('=')
      o[paramsArr[0]] = paramsArr[1]
    }
  }
  return o
}

// 判断对象是否为空
function isEmptyObj (e) {
  let t
  for (t in e) {
    return !1
  }
  return !0
}

// 下载
const downloadFile = function (filename, data) {
  try {
    if (this._cacheBlobUrl !== null) {
      URL.revokeObjectURL(this._cacheBlobUrl)
    }
    let dom = document.querySelector('#download')
    if (!dom) {
      dom = document.createElement('a')
      dom.style = 'display:none'
      document.body.appendChild(dom)
    }
    dom.download = filename
    const blob = new Blob([data], { type: 'application/json' })
    this._cacheBlobUrl = URL.createObjectURL(blob)
    dom.href = this._cacheBlobUrl
    dom.click()
  } catch (ex) {
    message.error('保存失败:请使用谷歌浏览器')
  }
}

const timeGTCToTimeString = function (span) {
  if (!span) return span
  const date = new Date(span)

  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
}

const host = window.location.host

// 页面跳转
const routes = {
  withdraw: `http://${host}/withdraw/add`,
  withdrawRecord: `http://${host}/withdraw/index`
}

//转化日期
Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1,                 //月份
    'd+': this.getDate(),                    //日
    'h+': this.getHours(),                   //小时
    'm+': this.getMinutes(),                 //分
    's+': this.getSeconds(),                 //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds()             //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

const u = navigator.userAgent
const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端


const timeDate = Date.prototype.format
module.exports = {
  formatMoney,
  GetUrlParams,
  isEmptyObj,
  money,
  timeDate,
  downloadFile,
  timeGTCToTimeString,
  isAndroid,
  isiOS,
  routes
}
